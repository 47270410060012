import "./style.css";
// import searchLogo from '../../../assets/search.png'
import kisiLogo from "../../assets/logo.svg";
import flagId from "../../assets/flagId.png";
import flagEn from "../../assets/flagEn.png";
import menuDrawer from "../../assets/menuDrawer.png";

import kisiMobile from "../../assets/kisiMobile.png";
import axios from "axios";
import { useTranslation, Trans } from "react-i18next";
import React, { useEffect, useState } from "react";
import Aos from "aos";
import { useDispatch, useSelector } from "react-redux";

import {
  useNavigate,
  useParams,
  Navigate,
  useLocation,
} from "react-router-dom";
import { NoNavbarFooter } from "../../helpers/NoNavbarFooter";

import {
  getPromos,
  getNews,
  getSliderImage,
  login,
  setAccessToken,
  setNews,
} from "../../store/action";
export default function Navbar() {
  const [navMobileShow, setNavMobileShow] = useState(false);
  const activeMenu = useSelector((state) => state.activeMenu);
  const page = window.location.pathname;
  const params = useLocation();
  const currentPath = params.pathname;
  const handleSubmenu = (par) => {
    switch (par) {
      case "blog":
        // console.log("tes");
        if (mobileSubmenuBlog) {
          setMobileSubmenuBlog(false);
          document.getElementById("mobileSubmenuBlog").className =
            "mobileSubmenuWrapper";
        } else {
          setMobileSubmenuBlog(true);
          document.getElementById("mobileSubmenuBlog").className =
            "mobileSubmenuWrapper mobileSubmenuWrapperActive";
        }

        break;

      case "produk":
        // console.log("tes");
        if (mobileSubmenuProduk) {
          setMobileSubmenuProduk(false);
          document.getElementById("mobileSubmenuProduk").className =
            "mobileSubmenuWrapper";
        } else {
          setMobileSubmenuProduk(true);
          document.getElementById("mobileSubmenuProduk").className =
            "mobileSubmenuWrapper mobileSubmenuWrapperActive";
        }

        break;
      case "about_us":
        // console.log("tes");
        if (mobileSubmenuAbout) {
          setMobileSubmenuAbout(false);
          document.getElementById("mobileSubmenuAbout").className =
            "mobileSubmenuWrapper";
        } else {
          setMobileSubmenuAbout(true);
          document.getElementById("mobileSubmenuAbout").className =
            "mobileSubmenuWrapper mobileSubmenuWrapperActive";
        }

        break;
      default:
        break;
    }
  };

  const isNoNavbar = () => {
    if (
     currentPath !== "/koins/reset-password" &&     currentPath !== "/koins/dev/reset-password" &&     currentPath !== "/koins/staging/reset-password" &&
      currentPath !== "/confirmation" &&
      currentPath.substring(0, 17) !== "/signature-spouse"
    ) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    Aos.init({ duration: 2000 });
    // console.log("NAVBARX",currentPath.substring(0,17));

    // window.scrollTo(0, 0);
    if (isNoNavbar()) {
      document.getElementById("navBar").style.boxShadow =
        "0px 4px 14px rgb(0 0 0 / 7%)";
      let allNavMenu = document.getElementsByClassName("nav-button");
      for (let i = 0; i < allNavMenu.length; i++) {
        const element = allNavMenu[i];
        // console.log(element.className);
        if (element.className !== activeMenu) {
          element.className =
            "nav-link nav-button monsterratMedium hoverableNav ";
        }
      }

      document.getElementById(activeMenu).className += " menuActive";
      if (navMobileShow) {
        document.getElementById("mobileNavbarMenuContainer").style.height =
          "100vh";
        document.getElementById("mobileNavbarMenuContainer").style.visibility =
          "visible";
        const mobileMenu = document.getElementsByClassName("mobileMenu");
        // console.log(mobileMenu);
        for (let i = 0; i < mobileMenu.length; i++) {
          const element = mobileMenu[i];
          element.style.marginTop = "10%";
          element.style.position = "relative";
          element.style.visibility = "visible";
        }
      } else {
        document.getElementById("mobileNavbarMenuContainer").style.height =
          "0px";
        document.getElementById("mobileNavbarMenuContainer").style.visibility =
          "hidden";
        const mobileMenu = document.getElementsByClassName("mobileMenu");
        // console.log(mobileMenu);
        for (let i = 0; i < mobileMenu.length; i++) {
          const element = mobileMenu[i];
          element.style.marginTop = "80%";
          element.style.position = "absolute";
          element.style.visibility = "hidden";
        }
      }
    }
  }, [activeMenu, navMobileShow]);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [flagIdClass, setFlagIdClass] = useState("flagImgActive");
  const [flagEnClass, setFlagEnClass] = useState("flagImgInactive");
  const [mobileSubmenuBlog, setMobileSubmenuBlog] = useState(false);
  const [mobileSubmenuProduk, setMobileSubmenuProduk] = useState(false);
  const [mobileSubmenuAbout, setMobileSubmenuAbout] = useState(false);
  const changeLanguage = (lng) => {
    // console.log("masuk" + lng);
    if (lng == "id") {
      setFlagIdClass("flagImgActive");
      setFlagEnClass("flagImgInactive");
    } else {
      // console.log("sini");
      setFlagIdClass("flagImgInactive");
      setFlagEnClass("flagImgActive");
    }

    i18n.changeLanguage(lng);
  };
  const mobileMenuHandle = (state) => {
    // console.log("asuw");
    Aos.refresh();
    setNavMobileShow(!state);
  };
  const changePage = (route) => {
    // console.log("masuk");

    navigate(route);
  };
  const openInNewTab = (url) => {
    var win = window.open(url, "_blank");
    win.focus();
  };

  const tes = (route) => {
    // axios({
    //   method: "get",
    //   url: "http://localhost:8000/article",
    // })
    //   .then((result) => {
    //     if (result.error) {
    //       console.log(result.error);
    //     } else {
    //       console.log(result.data);
    //     }
    //   })
    //   .then((result) => {})
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };
  const navigateMobile = (to) => {
    mobileMenuHandle(navMobileShow);
    if (mobileSubmenuBlog) {
      handleSubmenu("blog");
    }
    if (mobileSubmenuProduk) {
      handleSubmenu("produk");
    }
    if (mobileSubmenuAbout) {
      handleSubmenu("about_us");
    }
    navigate(to);
  };
  return (
    <>
      {isNoNavbar() ? (
        <div id="navBar" className="navigationBar fixed-top">
          {/* mobile navbar */}
          <nav className="navbar-light mobileNavbar">
            <div className=" menuLogoMobile">
              <button
                onClick={() => {
                  mobileMenuHandle(navMobileShow);
                }}
                className="navbar-toggler mobile-nav-button monsterratRegular"
                type="button"
                // data-bs-toggle="collapse"
                // data-bs-target="#navbarToggleExternalContent"
                // aria-controls="navbarToggleExternalContent"
                // aria-expanded="true"
                // aria-label="Toggle navigation"
              >
                <img className="mobileMenuImg" src={menuDrawer}></img>
              </button>
              <div className="logoKisiWrapperMobile center-vertical">
                <img
                  onClick={() => {
                    changePage("/");
                  }}
                  src={kisiLogo}
                  alt="logo-kisi"
                  className="logoKisiMobile"
                />
              </div>
              <div style={{ width: "100%" }}>
                <button
                  className="rounded-pill navbarDownload navbarMobileDownload nav-link  monsterratBlack"
                  href="#"
                  id="google-tag-tracker-registration-mobile"
                  onClick={() =>
                    openInNewTab("https://registration.kisi.co.id")
                  }
                >
                  Registrasi
                </button>
              </div>
            </div>
          </nav>
          <div
            // onClick={() => {
            //   mobileMenuHandle(navMobileShow);
            // }}
            id="mobileNavbarMenuContainer"
            className="mobileNavbarMenu"
          >
            <div className="mobileMenuWrapper">
              <div
                className="mobileMenu menu1 monsterratMedium grey"
                onClick={() => {
                  navigateMobile("/");
                }}
              >
                Beranda
              </div>
              <div
                className="mobileMenu menu2 monsterratMedium grey"
                // onClick={() => {
                //   navigate("/blog/artikel");
                // }}
              >
                <div onClick={() => handleSubmenu("blog")}>
                  {" "}
                  Info&nbsp;
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    fill="currentColor"
                    className="bi "
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </div>
                <div id="mobileSubmenuBlog" className="mobileSubmenuWrapper">
                  <div
                    className="mobileSubmenu"
                    onClick={() => navigateMobile("/blog/promo")}
                  >
                    Promo
                  </div>
                  <div
                    className="mobileSubmenu"
                    onClick={() => navigateMobile("/blog/artikel")}
                  >
                    Berita Pasar
                  </div>
                  <div
                    className="mobileSubmenu"
                    onClick={() => navigateMobile("/blog/rna")}
                  >
                    Riset dan Analisis
                  </div>{" "}
                  <div
                    className="mobileSubmenu"
                    onClick={() => navigateMobile("/blog/edukasi/list")}
                  >
                    Edukasi
                  </div>
                </div>
              </div>
              <div className="mobileMenu monsterratMedium grey">
                <div onClick={() => handleSubmenu("produk")}>
                  Layanan&nbsp;
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    fill="currentColor"
                    className="bi "
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </div>

                <div id="mobileSubmenuProduk" className="mobileSubmenuWrapper">
                  <div
                    className="mobileSubmenu"
                    onClick={() => navigateMobile("/produk/saham")}
                  >
                    Produk
                  </div>
                  <div
                    className="mobileSubmenu"
                    onClick={() => navigateMobile("/layanan/e_ipo")}
                  >
                    Services
                  </div>
                  <div
                    className="mobileSubmenu"
                    onClick={() => navigateMobile("/kisimobile")}
                  >
                    Aplikasi
                  </div>
                  <div
                    className="mobileSubmenu"
                    onClick={() => navigateMobile("/panduan/pembukaan_akun")}
                  >
                    Pedoman
                  </div>
                </div>
              </div>
              <div className="mobileMenu monsterratMedium grey">
                <div onClick={() => handleSubmenu("about_us")}>
                  Tentang Kami&nbsp;
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    fill="currentColor"
                    className="bi "
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </div>

                <div id="mobileSubmenuAbout" className="mobileSubmenuWrapper">
                  <div
                    className="mobileSubmenu"
                    onClick={() => navigateMobile("/tentangkami/profile")}
                  >
                    Tentang Kami
                  </div>
                  <div
                    className="mobileSubmenu"
                    onClick={() => navigateMobile("/cabang")}
                  >
                    Kantor Cabang / Galeri
                  </div>
                </div>
              </div>
              {/* <div
                className="mobileMenu monsterratMedium grey"
                onClick={() => {
                  navigateMobile("/tentangkami/profile");
                }}
              >
                About us
              </div> */}
            </div>
          </div>
          <div className="collapse " id="navbarToggleExternalContent">
            {/* <div className="p-2 navbarMobileMenu monsterratRegular">
          <div className="mobile-navbar-menu"
            onClick={() => {
              changePage("/");
            }}
          >
            Home
          </div>
          <div  className="mobile-navbar-menu">Flash News</div>
          <div className="mobile-navbar-menu"
            onClick={() => {
              changePage("/artikel");
            }}
          >
            {t("Article")}
          </div>
          <div className="mobile-navbar-menu"
            onClick={() => {
              changePage("/tentangkami");
            }}
          >
            About Us
          </div>
          <div  className="mobile-navbar-menu" onClick={() => openInNewTab("https://kisi.co.id/")}>
            Old Website
          </div>
        </div> */}
          </div>
          {/* <div className="uppernavbar-divider">
        <div>
          <span>ID   </span>
          <span>I</span> <span>   EN</span>{" "}
        </div>
        <div className="searchWrapper">
          <input
            className="searchBox"
            type="search"
            placeholder="Ketik Untuk mencari"
          ></input>
        </div>

        <div></div>
      </div> */}

          <div className="lowerNavbar">
            <div className="logoKisiWrapper">
              <img
                onClick={() => {
                  changePage("/");
                  window.scrollTo(0, 0);
                }}
                src={kisiLogo}
                alt="logo-kisi"
                className="logoKisi"
              />
            </div>
            <div className="menuWrapper ">
              {/* <a className="navbar-brand" href="#">Navbar</a> */}
              <button
                className="navbar-toggler mobileNavbarButton"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="navbarWrapper">
                <div className="navbar-nav">
                  {/* <li className="nav-item active">
                                    <a className="nav-link" onClick={() => { navigate('page2') }} href="#">Home <span className="sr-only" ></span></a>
                                </li> */}
                  <div className="nav-item">
                    <button
                      id="Home"
                      className="nav-link nav-button monsterratMedium"
                      href="#"
                      onClick={() => {
                        changePage("/");
                      }}
                    >
                      Beranda
                    </button>
                  </div>

                  <div className="nav-item navDialogMenu">
                    <button
                      className="nav-link nav-button monsterratMedium center-vertical hoverableNav"
                      id="Blog"
                      href="#"
                    >
                      <div className="navText">
                        {t("Info")} &nbsp;
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          fill="currentColor"
                          className="bi bi-chevron-down"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                          />
                        </svg>
                      </div>
                    </button>
                    <div className="navDialogWrapper blogPosition">
                      <div className="jago-syariah triangle"></div>
                      {/* <div className="arrow-up"></div> */}

                      <div className="navDialog">
                        <div className="navDialogSubmenu  monsterratMedium ">
                          <div
                            onClick={() => {
                              changePage("/blog/promo");
                            }}
                            className="dialogText grey"
                          >
                            Promo
                          </div>
                        </div>
                        <div className="navDialogSubmenu  monsterratMedium ">
                          <div
                            onClick={() => {
                              changePage("/blog/artikel");
                            }}
                            className="dialogText grey"
                          >
                            Berita Pasar
                          </div>
                        </div>
                        <div className="navDialogSubmenu  monsterratMedium">
                          <div
                            onClick={() => {
                              changePage("/blog/rna");
                            }}
                            className="dialogText grey"
                          >
                            Riset dan Analisis
                          </div>
                        </div>{" "}
                        <div className="navDialogSubmenu  monsterratMedium">
                          <div
                            onClick={() => {
                              changePage("/blog/edukasi/list");
                            }}
                            className="dialogText grey"
                          >
                            Edukasi
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="nav-item navDialogMenu assf">
                    <button
                      id="Produk"
                      className="center-vertical nav-link  nav-button monsterratMedium "
                      href="#XS"
                    >
                      Layanan &nbsp;
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        fill="currentColor"
                        className="bi bi-chevron-down"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                        />
                      </svg>
                    </button>
                    <div className="navDialogWrapper productPosition">
                      <div className="jago-syariah triangle"></div>
                      {/* <div className="arrow-up"></div> */}

                      <div className="navDialog">
                        <div className="navDialogSubmenu productSubmenu  monsterratMedium ">
                          <div
                            onClick={() => {
                              changePage("/produk/saham");
                            }}
                            className="dialogText grey"
                          >
                            Produk
                          </div>
                        </div>
                        <div className="navDialogSubmenu productSubmenu  monsterratMedium">
                          <div
                            onClick={() => {
                              navigate("/layanan/e_ipo");
                            }}
                            className="dialogText grey"
                          >
                            Services
                          </div>
                        </div>
                        <div className="navDialogSubmenu productSubmenu  monsterratMedium">
                          <div
                            onClick={() => {
                              navigate("/kisimobile");
                            }}
                            className="dialogText grey"
                          >
                            Aplikasi
                          </div>
                        </div>
                        <div className="navDialogSubmenu productSubmenu  monsterratMedium">
                          <div
                            onClick={() => {
                              navigate("/panduan/pembukaan_akun");
                            }}
                            className="dialogText grey"
                          >
                            Pedoman
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="nav-item navDialogMenu">
                    <button
                      id="tentangKami"
                      className="nav-link nav-button monsterratMedium"
                      href="#"
                      onClick={() => {
                        navigate("/tentangkami/profile");
                      }}
                    >
                      Tentang Kami
                    </button>
                    <div className="navDialogWrapper branchPosition">
                      <div className="jago-syariah triangle"></div>
                      {/* <div className="arrow-up"></div> */}

                      <div className="navDialog">
                        <div className="navDialogSubmenu productSubmenu  monsterratMedium">
                          <div
                            onClick={() => {
                              navigate("/cabang");
                            }}
                            className="dialogText grey"
                          >
                            Kantor Cabang / Galeri
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="nav-item ">
                    <button
                      className="rounded-pill navbarDownload nav-link  monsterratBlack"
                      href="#"
                      id="google-tag-tracker-registration-desktop"
                      onClick={() =>
                        openInNewTab("https://registration.kisi.co.id")
                      }
                    >
                      Registrasi
                    </button>
                  </div>
                </div>
                {/* <div className="flagContainer flex flexEnd">
              <div className="flagWrapper">
                {" "}
                <img
                  onClick={() => {
                    changeLanguage("id");
                  }}
                  src={flagId}
                  alt="logo-kisi"
                  className={"flagImg " + flagIdClass}
                />
              </div>
              <div className="flagWrapper">
                <img
                  onClick={() => {
                    changeLanguage("en");
                  }}
                  src={flagEn}
                  alt="logo-kisi"
                  className={"flagImg " + flagEnClass}
                />
              </div>
            </div> */}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
